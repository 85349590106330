import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import "./index.css"
import Lottie from "lottie-react";
import groovyWalkAnimation from "../images/slackoutAnimation3.json";
import groovyWalkAnimation2 from "../images/stars.json";

const Animation1 = () => <Lottie animationData={groovyWalkAnimation} loop={true} />;

const GetItOn = () => (
  <div className="getItOn">
    <div>get it on :
    </div>

    <a href='https://aescripts.com/slackout/'>
      <button className="exchangeButton" >
        <div className="adobeExchangeButtonInside">
          <StaticImage
            src="../images/logoaescripts.png"
            width={30}
            height={30}
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt="AEScripts Logo"
            style={{ marginRight: `5px`, minWidth: 20 }}
          />
          AE Scripts
        </div>
      </button></a>

    {/* <a href='https://exchange.adobe.com/creativecloud.details.108791.html'>
  <button className="exchangeButton">
    <div className="adobeExchangeButtonInside">
    <img width="30" src="https://www.adobe.com/content/dam/cc/icons/Adobe_Corporate_Horizontal_Red_HEX.svg"></img>
    Adobe Exchange
    </div>
  </button></a> */}
  </div>
)

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <div className='wrappywrappy'>
      <div className='wrappy'>
        <div className='images'>
          <Animation1></Animation1>
          {/* <StaticImage
            src="../images/slackOutSplash.png"
            width={900}
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt="A Slack Logo"
            style={{ marginBottom: `1.45rem` }}
          /> */}
        </div>
        <h1 className='titleSlackOut'>SLACK OUT !</h1>
        <h2>A Premiere Pro & After Effects Panel for Slack</h2>

        <h3>The fastest way to export and send your video to Slack !</h3>

        <GetItOn />
      </div>
      <div className='paragraphWrap'>
        <div style={{ paddingTop: "56.25%", position: "relative" }}>
          <iframe src="https://player.vimeo.com/video/752691627?h=dc9ca66cbe&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }} title="Slack Out !"></iframe>
        </div>
        <script src="https://player.vimeo.com/api/player.js"></script>
        <div className='paragraph'>
          <p>The <strong>Slack Out panel for Premiere Pro & After Effects</strong> automates the exporting and sending of your edits to Slack,
            so that when you are finished with editing and need to share your work, you are exactly one click away from grabbing a cup of coffee and relax !</p>
          <p>This plugin will&nbsp; :</p>
          <ul>
            <li>Handle the naming of your file in a professional manner and increment it so that it's unique;&nbsp;</li>
            <li>Let you quickly select an export configuration preset, even custom ones;&nbsp;</li>
            <li>Export your file in an "export" folder next to your project file or in a custom folder;&nbsp;</li>
            <li>Finally, it can upload your video to Slack, either to a channel or a user.</li>
            <li>The panel will memorize all settings per sequence, so that you'll need to set it once</li>
          </ul>
          <div>&nbsp;</div>
          <div className='centerImg'>
            <StaticImage
              src="../images/panel.png"
              width={300}
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt="A Slack panel"
              style={{
                marginBottom: `1.45rem`,
                border: '1px solid #3cb1ff',
                boxShadow: '4px 5px 7px 0px rgba(0, 0, 0, 0.4)'
              }}
            />
          </div>
          <div>&nbsp;</div>
          <div>You can connect the panel to multiple Slack teams by clicking the little slack icon. Your credentials will be kept inside the panel folder files until you decide to log out.</div>
          <div>&nbsp;</div>
          <div>&nbsp;</div>
          <p>And the cool thing is the panel will remember all your settings for each project and even for each sequence, so that you can set it up <strong>once</strong>, and you don't have to worry about it anymore.</p>
          <p>The panel has two export buttons : <strong>Draft</strong> and <strong>Final</strong>. Both have their own codec configuration next to it. The idea is that you can set up a light size file for draft export and high quality file for final exports. "<strong>[DRAFT]</strong>" or "<strong>[FINAL]</strong>" will be indicated at the end of the file so that you and your client are aware of the file type.</p>
          <p>The panel also has a really useful <strong>"Go to export folder" button</strong> that will open the finder/explorer window to your destination folder.</p>
          <p>If you created a new channel in Slack or a new export preset in Premiere Pro / After effects, you can hit the <strong>update button</strong> in the top right corner. Click it to reflect the changes in the panel.</p>



        </div>
        <div className='paragraph'>
          <div><strong>Installation</strong></div>
          <div>&nbsp;</div>
          <p>This plugin will require you to have a valid Premiere Pro or After Effects licence. It is intended to work inside of those apps. 
            It doesn't require you to have a Slack account in order to work, as you can always use it just to export videos locally without sending them to Slack.</p>

          <p>1 ) Go to <a href='https://aescripts.com/slackout/'>the Slack Out product page</a> on aescripts.com and licence it. </p>
          <p>2 ) Install the plugin with <a href='https://aescripts.com/learn/aescripts-aeplugins-manager-app/'>the AE script manager tool</a>.</p>
          <p>3 ) Open Premiere Pro / After Effects. In the menu, go to Window > Extensions > Slack Out to open the panel.</p>
          <p>4 ) To connect the panel to your Slack team, first check that your internet connection is up and that your app can access it.
            Click the Slack icon in the top left corner of the panel. Your default browser should pop up and show a Slack auth page, 
            asking you to allow the panel to connect to your Slack Team. If you are a member of multiple teams, you can switch by clicking on the top-right button of the page. 
            Click the “allow” button and come back to your app.</p>
            <p>5 ) You can connect multiple teams and switch between them in the panel. 
              To connect to another team, click on the top left icon. A fly-out menu will pop up. Click on “Connect to another Slack”</p>






        </div>
        <div className='paragraph'>
          <div><strong>Contact</strong></div>
          <div>&nbsp;</div>

          <p>
            Do you have any questions ? Or do you need some help ? You found a bug ? <br/>
            Please reach out to me at : <strong>picarovideo@gmail.com</strong> 
          </p>
        </div>

        <div className='paragraph'>
          <p>
            <Link to="/privacypolicy/">Privacy policy</Link> <br />
          </p>
        </div>

      </div>
    </div>
    <div style={{ margin: '10px' }}></div>
    <GetItOn />
  </Layout>
)

export default IndexPage

